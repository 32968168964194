$color-primary: #3C5484;
$color-primary-hover: #2c3e61;
$color-secondary: #7E6143;
$color-tertiary: #304979;
$color-cta: #3C5484;
$color-danger: #FF0000;

// var name from https://chir.ag/projects/name-that-color/#F0F0F0
$color-white: #FFFFFF;
$color-gallery: #F0F0F0;
$color-grey--light: #F6F6F6;
$color-grey: #585856;
$color-grey--dark: #454241;
$color-black: #282828;

$color-sku: #a2a2a2;

$font-family--heading: "Gill Sans", Helvetica, Arial, sans-serif !default;
$font-family--body: "Gill Sans", Helvetica, Arial, sans-serif !default;

$color-login-bg: $color-gallery;
$compare-remove-icon-color: $color-white;

// Header
$header-background-colour: linear-gradient(180deg, #2e2d2c 0%, #454241 40%, #454241 100%);
$header-background-colour--mobile: linear-gradient(180deg, #2e2d2c 0%, #454241 40%, #454241 100%);

// dropship settings
$dropship-notice-bg: $color-secondary;
$dropship-notice-icon-bg: $color-white;
$dropship-notice-color: $color-white;
