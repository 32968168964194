// _actions.scss
// Suggestion: Add border option
$action-book-appointment: "book-appointment" null $color-white "icon--book-appointment.svg" above mobile;
$action-requisition-list: "requisition-list-button.toggle.change" $color-secondary $color-white "icon--favourites.svg" before false;

// BUG: Must have 2 actions in the list for the mixin to work. If only 1 action is in the list, the mixin will not work.
// TODO - Fix this bug
$child-actions-list: $action-book-appointment,
  $action-requisition-list;

// _header.scss
// TODO - Basic header layout needs to be looked at in the parent theme.
// Suggestion - The parent theme to have layout options to select from here, for example: Logo:Search:Icons or Search:Logo:Icons
$panel-background-color: $color-primary;
$panel-color: $color-white;
$header-background-color: $color-grey--dark;
$header-links-color-hover: $color-white;
$header-icon-links-color: $color-white;
// $header-color: $color-white;
$search-block-max-width--desktop: 100%;
$search-block-content-max-width--desktop: 671px;
$header-content-column-gap--desktop: 24px;

$header-links-font-weight: normal;
$tax-switcher-font-weight: normal;
$main-menu-link-font-weight--desktop: 600;
$main-menu-link-font-size--desktop: 20px;

$main-menu-color: $color-primary;
$main-menu-background-color: $color-white;
$main-menu-background-color--desktop: $color-grey--dark;
$main-menu-padding: 0;
$main-menu-margin: 0;
$main-menu-list-justify-content--desktop: space-around;
$menu-hover-item-text-color--hover: #454241;

$main-menu-item-background-color: $color-white;

$main-menu-link-color: $color-black;
$main-menu-link-color--desktop: $color-white;
$main-menu-inner-link-color: $color-black;
$main-menu-inner-parent-link-color: $color-primary;

$main-menu-inner-list-background-color: $color-white;

$main-menu-submenu-open-icon-color: $color-primary;

$menu-hover-item-background-color: $color-white;
$menu-hover-item-text-color: $color-grey--dark;

$header-gradient-colour-dark: rgb(46, 45, 44);
$header-gradient-colour-light: rgba(69, 66, 65, 1);

$page-title-border-color: $color-secondary;

// footer.scss
$footer-menu-background-color: $color-black;
$footer-menu-wrapper-label-font-size--desktop: 18px;
$footer-menu-wrapper-label-padding-bottom--desktop: 8px;
$newsletter-background-color: #585856;
$newsletter-padding: 0;
$newsletter-padding--tablet: 0;

$compare-products-product-item-background-color: $color-tertiary;

$footer-menu-background-color: #585856;
$footer-menu-background-color--desktop: #585856;
$absolute-footer-background-color: #585856;
$absolute-footer-background-color--desktop: #454241;

$usp-bar-font-weight--desktop: normal;

$related-product-title-text-transform: uppercase;
$related-product-title-font-weight: normal;

$pdp-usp-item-title-font-weight: normal;
$pdp-usp-item-title-text-transform: uppercase;

$dropdown-title-font-weight: normal;
$dropdown-title-text-transform: uppercase;

$amblog-featured-view-more-font-weight: normal;

$product-card-item-name-font-weight: normal;
$product-card-item-name-font-weight--desktop: normal;

$icon-links-cart-background-color-hover: #304979;

$counter-background: #FFE600;
$counter-text-color: #373737;

$page-title-font-size: 27px;

$counter-number-position-top: -10px;
$counter-number-position-right: 10px;
